
import { Options, Vue } from "vue-class-component";
import Item from "../../service/Item";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class Items extends Vue {
  private imagePath = "";
  private lists = [];
  private allServices;
  private allDepartments;
  private item;
  private productStatus = "";
  private loading = false;
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private keyword = "";
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Items" },
  ];

  private itemTypes = [{ key: "Product" }, { key: "Service" }];

  private popupConditions = [{ key: "No" }, { key: "Yes" }];

  private product = {
    id: 0,
    name: "",
    service: "",
    department: "",
    hasPopUpCondition: "",
    itemType: "",
    rack: "not_rack",
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  private serviceId = "";
  private serviceObj;

  private departmentId = "";
  private departmentObj;

  private selectedPopConditionKey = "";
  private selectedPopConditionObj;

  private selectedItemTypeKey = "";
  private selectedItemTypeObj;
  private toast;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
    this.loadServicesAndDepartment();
  }

  created() {
    this.item = new Item();
    this.imagePath = this.item.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  //lOAD SERVICES AND DEPARTMENTS FOR DROPDOWN FIELD
  loadServicesAndDepartment() {
    this.item.getServicesAndDepartments().then((data) => {
      this.allServices = data.services;
      this.allDepartments = data.departments;
    });
  }

  setServiceAndDepartment() {
    this.allServices.filter((elem) => {
      if (elem.id == this.serviceId) {
        this.serviceObj = elem;
      }
    });

    this.allDepartments.filter((elem) => {
      if (elem.id == this.departmentId) {
        this.departmentObj = elem;
      }
    });

    this.popupConditions.filter((elem) => {
      if (elem.key == this.selectedPopConditionKey) {
        this.selectedPopConditionObj = elem;
      }
    });

    this.itemTypes.filter((elem) => {
      if (elem.key == this.selectedItemTypeKey) {
        this.selectedItemTypeObj = elem;
      }
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      service: "",
      department: "",
      hasPopUpCondition: "",
      itemType: "",
      rack: "not_rack",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Item";
    this.productDialog = true;
    this.checkPagination = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.item.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.item.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        service: "",
        department: "",
        hasPopUpCondition: "",
        itemType: "",
        rack: "not_rack",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Items";
    this.productDialog = true;
    this.checkPagination = true;

    this.item.getItem(data).then((res) => {
      if (res.length > 0) {
        this.serviceId = res[0].service_id;
        this.departmentId = res[0].department_id;
        this.selectedPopConditionKey = res[0].has_condition;
        this.selectedItemTypeKey = res[0].item_type;
        this.setServiceAndDepartment();

        this.product = {
          id: res[0].id,
          name: res[0].item_name,
          service: this.serviceObj,
          department: this.departmentObj,
          hasPopUpCondition: this.selectedPopConditionObj,
          itemType: this.selectedItemTypeObj,
          rack: res[0].rack,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.id,
      name: data.item_name,
      service: "",
      department: "",
      hasPopUpCondition: "",
      itemType: "",
      rack: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.item.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.item.getItems(page).then((data) => {
      this.lists = data.items_list;
      this.totalRecords = data.total_item_count;
      this.limit = data.limit_per_page;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.item.getSearchedItems(this.keyword).then((data) => {
        this.lists = data.items_list;
        // this.totalRecords = data.total_item_count;
        // this.limit = data.limit_per_page;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }
}
