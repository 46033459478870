
import { Options, Vue } from "vue-class-component";
import ApprovalService from "../../service/ApprovalService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import {
  ScheduleList,
  AttendanceList,
  ApprovedList,
} from "../hoursApproval/IHoursAppoval";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";

@Options({
  components: { VueCal, AssociatePinVerification },
})
export default class HoursApproval extends Vue {
  private ApprovalTxn;
  private scheduleID = "";

  private loginDetails = {
    title: "Add Associate Login Timings",
    attendenceID: "",
    loginTime: "",
    logoutTime: "",
    loginDate: "",
  };

  private managerApproved = "";
  private verificationType = "manager";
  private searchDate = "";
  private loginHoursDialog = false;
  private submitted = false;
  private approveBoxDialog = false;
  private manualApproveDailog = false;
  private toggleCheckBox = false;
  private activeAssociateID = "";
  private activeAssociateName = "";
  private weekStartingDate = "";
  private weekEndingDate = "";
  private weekNo = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Dashboard", to: "dashboard" },
    { label: "Hours approval" },
  ];
  private toast;
  private associateList: ScheduleList[] = [];
  private approvedList: ScheduleList[] = [];
  private attendanceList: AttendanceList[] = [];
  private approveManually = {
    hours: 0,
    freezed: false,
    shiftDate: "",
    counterId: 0,
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.ApprovalTxn = new ApprovalService();
    this.toast = new Toaster();
  }

  mounted() {
    this.getSchedule();
  }

  setLoginTime(dueTime) {
    this.loginDetails.loginTime = dueTime;
  }

  setLogoutTime(dueTime) {
    this.loginDetails.logoutTime = dueTime;
  }

  parseDate(date) {
    if (date != "") {
      const d2 = moment(String(date)).format("YYYY-MM-DD");
      this.loginDetails.loginDate = d2;
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  getSchedule() {
    // this.associateList = [];
    this.ApprovalTxn.getAssociateShifts(this.searchDate).then((res) => {
      const d = this.camelizeKeys(res);
      const s = d.employeeList.scheduleList;
      if (s != null) {
        this.associateList = s;
      } else {
        this.associateList = [];
      }

      this.scheduleID = d.employeeList.id;
      this.weekStartingDate = d.postStartingDate;
      this.weekEndingDate = d.postEndingDate;
      this.weekNo = d.weekNo;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  get filterAssociates() {
    let copyList: ScheduleList[] = [];

    if (this.associateList != null) {
      copyList = [...this.associateList];
    }

    const uniqueElementsBy = (arr, fn) =>
      arr.reduce((acc, v) => {
        if (!acc.some((x) => fn(v, x))) acc.push(v);
        return acc;
      }, []);

    const list = uniqueElementsBy(
      copyList,
      (a, b) => a.employeeId == b.employeeId
    );

    return list;
  }

  checkApprovedStatus(associateID) {
    let associateStatus = true;

    this.associateList.forEach((e) => {
      if (e.approveStatus == "" && e.employeeId == associateID) {
        associateStatus = false;
      }
    });

    return associateStatus;
  }

  openSchedule(empID, associateName) {
    this.activeAssociateID = empID;
    this.activeAssociateName = associateName;

    this.ApprovalTxn.getAssociateLogin(
      empID,
      this.weekStartingDate,
      this.weekEndingDate
    ).then((res) => {
      const d = this.camelizeKeys(res);
      this.attendanceList = d;
    });
  }

  get filterAssociatePaycodes() {
    const schedule: ScheduleList[] = [];

    this.associateList.forEach((e) => {
      if (e.employeeId == this.activeAssociateID) {
        schedule.push(e);
      }
    });
    return schedule;
  }

  getTimeDiff(end, start) {
    let ms = moment(end, "HH:mm").diff(moment(start, "HH:mm"));
    let d = moment.duration(ms);

    if (d.hours() > 5 && d.hours() <= 10) {
      end = moment(end, "HH:mm").subtract(30, "minutes").format("HH:mm");
    } else if (d.hours() > 10) {
      end = moment(end, "HH:mm").subtract(1, "hour").format("HH:mm");
    }

    ms = moment(end, "HH:mm").diff(moment(start, "HH:mm"));
    d = moment.duration(ms);

    const hours = d.hours();
    const minutes = d.minutes();
    return hours + " Hrs & " + minutes + " min";
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  filterAssociateLoginDetails(empID, date) {
    const logins: AttendanceList[] = [];

    this.attendanceList.forEach((e) => {
      if (e.employeeId == empID && date == e.date && e.timeOut != "00:00:00") {
        logins.push(e);
      }
    });

    return logins;
  }

  filterTotalLogin(empID, date) {
    let totalHrs = 0;

    this.attendanceList.forEach((e) => {
      if (e.employeeId == empID && date == e.date && e.timeOut != "00:00:00") {
        const ms = moment(e.timeOut, "HH:mm").diff(moment(e.timeIn, "HH:mm"));
        const d = moment.duration(ms).asHours();
        totalHrs = totalHrs + d;
      }
    });

    return Math.round(totalHrs * 100) / 100;
  }

  checkAllBoxes() {
    this.toggleCheckBox = !this.toggleCheckBox;

    if (this.toggleCheckBox == true) {
      this.approvedList = this.associateList;
    } else {
      this.approvedList = [];
    }
  }

  acceptApprovedHours() {
    const list: ApprovedList[] = [];
    this.approveBoxDialog = false;
    if (this.approvedList.length > 0) {
      this.approvedList.forEach((e) => {
        if (e.employeeId == this.activeAssociateID) {
          const totalHrs = this.filterTotalLogin(e.employeeId, e.shiftDate);
          list.push({
            counterID: e.counterId,
            approvedHours: totalHrs,
          });
        }
      });

      this.ApprovalTxn.postCheckListApprove(
        this.scheduleID,
        list,
        this.managerApproved
      ).then((res) => {
        this.toast.handleResponse(res);
        this.getSchedule();
        this.approvedList = [];
      });
    } else {
      this.toast.showWarning("Please choose associate shifts to approve");
    }
  }

  openManuyallHours(counterId, associateID, shiftDate) {
    this.manualApproveDailog = true;
    this.approveManually.hours = this.filterTotalLogin(associateID, shiftDate);
    this.approveManually.shiftDate = shiftDate;
    this.approveManually.counterId = counterId;
  }

  saveManuallyHours() {
    this.submitted = true;
    if (this.approveManually.hours > 0) {
      this.ApprovalTxn.updateManualHours(
        this.approveManually,
        this.scheduleID,
        this.managerApproved
      ).then((res) => {
        this.toast.handleResponse(res);
        this.getSchedule();
      });
      this.manualApproveDailog = false;
    }
  }

  openTimingDailog() {
    this.loginHoursDialog = true;
    this.loginDetails.title = "Add Associate Login Timings";
    this.loginDetails.attendenceID = "";
    this.loginDetails.loginTime = "";
    this.loginDetails.logoutTime = "";
    this.loginDetails.loginDate = "";
  }

  addAssociateLogin() {
    if (
      this.loginDetails.loginTime == "" ||
      this.loginDetails.logoutTime == "" ||
      this.loginDetails.loginDate == ""
    ) {
      this.toast.showWarning(
        "Please set date login and logout timings for associate to proceed"
      );
    } else {
      this.ApprovalTxn.addAssociateLogin(
        this.loginDetails.loginTime,
        this.activeAssociateID,
        this.managerApproved,
        this.loginDetails.logoutTime,
        this.loginDetails.loginDate
      ).then((res) => {
        this.toast.handleResponse(res);
        this.openSchedule(this.activeAssociateID, this.activeAssociateName);
      });
      this.loginHoursDialog = false;
    }
  }

  updateAssociateLogin() {
    if (
      this.loginDetails.loginTime == "" ||
      this.loginDetails.logoutTime == "" ||
      this.loginDetails.loginDate == ""
    ) {
      this.toast.showWarning(
        "Please set date login and logout timings for associate to proceed"
      );
    } else {
      this.ApprovalTxn.updateAssociateLogin(
        this.loginDetails.loginTime,
        this.loginDetails.attendenceID,
        this.managerApproved,
        this.loginDetails.logoutTime,
        this.loginDetails.loginDate
      ).then((res) => {
        this.toast.handleResponse(res);
        this.openSchedule(this.activeAssociateID, this.activeAssociateName);
      });
      this.loginHoursDialog = false;
    }
  }

  updateLoginTimes(id, timeOut, timeIn, shiftDate) {
    this.loginHoursDialog = true;
    this.loginDetails.title = "Update Associate Login Timings";
    this.loginDetails.loginTime = timeIn;
    this.loginDetails.attendenceID = id;
    this.loginDetails.logoutTime = timeOut;
    this.loginDetails.loginDate = shiftDate;
  }

  closePINDialog(params) {
    this.managerApproved = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }
}
