<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <input
              type="date"
              class="form-control input-lg"
              v-model="searchDate"
            />
            <Button
              icon="pi pi-search"
              class="p-button-success p-pr-5 p-pl-5"
              @click="getSchedule()"
            />
          </div>
          <Button
            icon="pi pi-print"
            class="p-ml-2 p-pr-5 p-pl-5 p-button-warning"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <div class="col-md-12 text-center p-p-0">
          <h5 class="checkout-heading">
            Week No : {{ weekNo }}
            <i class="pi pi-ellipsis-h"></i>
            Week Date Range {{ formatDate(weekStartingDate) }} -
            {{ formatDate(weekEndingDate) }}
            <i class="pi pi-ellipsis-h"></i>
            No of Associates : {{ filterAssociates.length }}
          </h5>
        </div>
        <DataTable
          :value="filterAssociates"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column field="employeeId" header="Associate ID">
            <template #body="slotProps">
              <span
                :class="{
                  highlighted:
                    checkApprovedStatus(slotProps.data.employeeId) == false,
                }"
              >
                {{ slotProps.data.employeeId }}
              </span>
            </template>
          </Column>
          <Column header="Associate Full Name">
            <template #body="slotProps">
              <b
                >{{ slotProps.data.employeeFirstName.toUpperCase() }}
                {{ slotProps.data.employeeLastName.toUpperCase() }}
              </b>
            </template>
          </Column>
          <Column
            field="employeeJobClass"
            header="Associate Job Class"
          ></Column>
          <Column field="employeeHourlyRate" header="Hourly Rate">
            <template #body="slotProps">
              ${{ formatAmount(slotProps.data.employeeHourlyRate) }}
            </template>
          </Column>
          <Column field="employeeMagic" header="Magic No">
            <template #body="slotProps">
              ${{ formatAmount(slotProps.data.employeeMagic) }}
            </template>
          </Column>
          <Column header="Action" headerStyle="width: 100px">
            <template #body="slotProps">
              <Button
                icon="pi pi-calendar"
                style="width: 80px"
                class="p-button-success p-p-1"
                @click="
                  openSchedule(
                    slotProps.data.employeeId,
                    slotProps.data.employeeFirstName +
                      ' ' +
                      slotProps.data.employeeLastName
                  )
                "
              />
            </template>
          </Column>
        </DataTable>
        <div class="p-mt-4" v-if="activeAssociateID != ''">
          <div class="col-md-12 p-p-2 checkout-heading">
            <div class="p-d-flex p-jc-between">
              <div class="p-mt-2">
                <h5>
                  <i class="pi pi-calendar"></i> Associate Working Schedule For
                  <span class="highlight-associate">
                    {{ activeAssociateName }} ( {{ activeAssociateID }} )
                  </span>
                </h5>
              </div>
              <div>
                <span class="p-buttonset">
                  <Button
                    label="Add Logged Hrs"
                    class="p-button-info"
                    @click="openTimingDailog()"
                    icon="pi pi-plus-circle"
                  />
                  <Button
                    label="Approve Hours"
                    class="p-button-success"
                    @click="approveBoxDialog = true"
                    icon="pi pi-check-circle"
                  />
                  <Button
                    label="Print"
                    class="p-button-warning"
                    icon="pi pi-print"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-12 p-p-0 p-m-0">
            <table class="table table-bordered schedule-style">
              <tr>
                <th>Date</th>
                <th>Pay code</th>
                <th class="ap-sch-hrs">
                  <i class="pi pi-clock"></i> Schedule hrs
                </th>
                <th class="ap-chk-hrs">
                  <input
                    type="checkbox"
                    @change="checkAllBoxes()"
                    class="loggedin-checkbox"
                  />
                  <i class="pi pi-clock"></i>
                  Logged Hrs
                </th>
                <th class="ap-style-hrs">
                  <i class="pi pi-check-circle"></i> Approved Hrs
                </th>
                <th>Manual</th>
                <th>Notes</th>
              </tr>
              <tr v-for="associate in filterAssociatePaycodes" :key="associate">
                <td>
                  <span class="shift-date">{{
                    formatDate(associate.shiftDate)
                  }}</span>
                </td>
                <td>{{ associate.paycode.toUpperCase() }}</td>
                <td class="ap-sch-hrs">
                  <i class="pi pi-clock"></i>
                  {{ getTimeDiff(associate.timeOut, associate.timeIn) }}
                  <p class="p-p-0 p-m-0">
                    <small class="schedule-timings"
                      >{{ formatTime(associate.timeIn) }} -
                      {{ formatTime(associate.timeOut) }}</small
                    >
                  </p>
                </td>
                <td class="ap-chk-hrs">
                  <input
                    type="checkbox"
                    v-model="approvedList"
                    :value="associate"
                    class="loggedin-checkbox"
                  />
                  <i class="pi pi-clock"></i>
                  {{
                    filterTotalLogin(associate.employeeId, associate.shiftDate)
                  }}
                  Hrs
                  <template
                    v-for="f in filterAssociateLoginDetails(
                      associate.employeeId,
                      associate.shiftDate
                    )"
                    :key="f"
                  >
                    <small
                      @click="
                        updateLoginTimes(f.id, f.timeOut, f.timeIn, f.date)
                      "
                      class="p-m-0 schedule-timings"
                      >{{ formatTime(f.timeIn) }} - {{ formatTime(f.timeOut) }}
                    </small>
                  </template>
                </td>
                <td class="ap-style-hrs">
                  <i class="pi pi-clock"></i> {{ associate.aprrovedHrs }} Hrs
                </td>
                <td>
                  <Button
                    class="p-button-primary p-pl-5 p-pr-5"
                    icon="pi pi-plus-circle"
                    @click="
                      openManuyallHours(
                        associate.counterId,
                        associate.employeeId,
                        associate.shiftDate
                      )
                    "
                  />
                </td>
                <td style="width: 15vw; font-size: 14px">
                  <small>{{ associate.notes }}</small>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Dialog
    v-model:visible="approveBoxDialog"
    :style="{ width: '450px' }"
    header="Confirm"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1rem" />
      <span>Are you sure to approve the selected list ? </span>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="approveBoxDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-button p-button-success"
        @click="acceptApprovedHours"
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="loginHoursDialog"
    :style="{ width: '80vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        {{ loginDetails.title }}
      </h5>
    </template>
    <div class="row">
      <div class="col-md-12">
        <p>
          <b
            >Associate ID : {{ activeAssociateName }} ({{
              activeAssociateID
            }})</b
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="p-d-flex p-jc-between">
          <div class="p-mb-2 p-mr-2 pull-right">
            <vue-cal
              hide-view-selector
              v:disable-views="['years', 'year', 'week']"
              :time="true"
              :selected-date="loginDetails.loginDate"
              @cell-focus="parseDate($event)"
              :dblclickToNavigate="false"
              active-view="month"
              class="vuecal--rounded-theme vuecal--blue-theme"
              style="height: 63vh"
              xsmall
            >
            </vue-cal>
          </div>
          <div class="p-mb-2 p-mr-2 pull-right">
            <h5 class="date-time-clock-hrs-heading">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              Login
            </h5>
            <div class="clock-hours-timming">
              <h5 @click="setLoginTime('08:00')" class="date-time-clock-hrs">
                08:00 AM
              </h5>
              <h5 @click="setLoginTime('09:00')" class="date-time-clock-hrs">
                09:00 AM
              </h5>
              <h5 @click="setLoginTime('10:00')" class="date-time-clock-hrs">
                10:00 AM
              </h5>
              <h5 @click="setLoginTime('11:00')" class="date-time-clock-hrs">
                11:00 AM
              </h5>
              <h5 @click="setLoginTime('12:00')" class="date-time-clock-hrs">
                12:00 PM
              </h5>
              <h5 @click="setLoginTime('13:00')" class="date-time-clock-hrs">
                01:00 PM
              </h5>
              <h5 @click="setLoginTime('14:00')" class="date-time-clock-hrs">
                02:00 PM
              </h5>
              <h5 @click="setLoginTime('15:00')" class="date-time-clock-hrs">
                03:00 PM
              </h5>
              <h5 @click="setLoginTime('16:00')" class="date-time-clock-hrs">
                04:00 PM
              </h5>
              <h5 @click="setLoginTime('17:00')" class="date-time-clock-hrs">
                05:00 PM
              </h5>
              <h5 @click="setLoginTime('18:00')" class="date-time-clock-hrs">
                06:00 PM
              </h5>
              <h5 @click="setLoginTime('19:00')" class="date-time-clock-hrs">
                07:00 PM
              </h5>
              <h5 @click="setLoginTime('20:00')" class="date-time-clock-hrs">
                08:00 PM
              </h5>
              <h5 @click="setLoginTime('21:00')" class="date-time-clock-hrs">
                09:00 PM
              </h5>
            </div>
            <input
              type="time"
              name="get_the_time"
              id="get_the_time"
              class="set_the_time_value form-control"
              v-model="loginDetails.loginTime"
            />
          </div>
          <div class="p-mb-2 p-mr-2 pull-right">
            <h5 class="date-time-clock-hrs-heading">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              Logout
            </h5>
            <div class="clock-hours-timming">
              <h5 @click="setLogoutTime('08:00')" class="date-time-clock-hrs">
                08:00 AM
              </h5>
              <h5 @click="setLogoutTime('09:00')" class="date-time-clock-hrs">
                09:00 AM
              </h5>
              <h5 @click="setLogoutTime('10:00')" class="date-time-clock-hrs">
                10:00 AM
              </h5>
              <h5 @click="setLogoutTime('11:00')" class="date-time-clock-hrs">
                11:00 AM
              </h5>
              <h5 @click="setLogoutTime('12:00')" class="date-time-clock-hrs">
                12:00 PM
              </h5>
              <h5 @click="setLogoutTime('13:00')" class="date-time-clock-hrs">
                01:00 PM
              </h5>
              <h5 @click="setLogoutTime('14:00')" class="date-time-clock-hrs">
                02:00 PM
              </h5>
              <h5 @click="setLogoutTime('15:00')" class="date-time-clock-hrs">
                03:00 PM
              </h5>
              <h5 @click="setLogoutTime('16:00')" class="date-time-clock-hrs">
                04:00 PM
              </h5>
              <h5 @click="setLogoutTime('17:00')" class="date-time-clock-hrs">
                05:00 PM
              </h5>
              <h5 @click="setLogoutTime('18:00')" class="date-time-clock-hrs">
                06:00 PM
              </h5>
              <h5 @click="setLogoutTime('19:00')" class="date-time-clock-hrs">
                07:00 PM
              </h5>
              <h5 @click="setLogoutTime('20:00')" class="date-time-clock-hrs">
                08:00 PM
              </h5>
              <h5 @click="setLogoutTime('21:00')" class="date-time-clock-hrs">
                09:00 PM
              </h5>
            </div>
            <input
              type="time"
              name="get_the_time"
              id="get_the_time"
              class="set_the_time_value form-control"
              v-model="loginDetails.logoutTime"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        type="submit"
        v-if="loginDetails.attendenceID == ''"
        label="Save Hours"
        icon="pi pi-check"
        class="p-button-primary"
        @click="addAssociateLogin"
      />
      <Button
        type="submit"
        v-if="loginDetails.attendenceID != ''"
        label="Update Hours"
        icon="pi pi-check"
        class="p-button-primary"
        @click="updateAssociateLogin"
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="manualApproveDailog"
    :style="{ width: '30vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        Save Associate approved hours
      </h5>
    </template>
    <div class="p-field">
      <label for="name">Approved Hours:</label>
      <InputText
        id="name"
        v-model.trim="approveManually.hours"
        required="true"
        autofocus
        :class="{ 'p-invalid': submitted && !approveManually.hours }"
      />
      <small class="p-invalid" v-if="submitted && !approveManually.hours"
        >Hours is required.</small
      >
    </div>
    <div class="p-field">
      <label>
        <input
          type="checkbox"
          class="schedule_hrs_chk"
          v-model="approveManually.freezed"
        />
        Check to Freeze it</label
      >
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Save Hours"
        icon="pi pi-check"
        class="p-button-primary"
        @click="saveManuallyHours"
      />
    </template>
  </Dialog>

  <AssociatePinVerification
    :receiptDetail="{ associateType: verificationType }"
    v-on:emitAssociatePinEvent="closePINDialog"
  />
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ApprovalService from "../../service/ApprovalService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import {
  ScheduleList,
  AttendanceList,
  ApprovedList,
} from "../hoursApproval/IHoursAppoval";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";

@Options({
  components: { VueCal, AssociatePinVerification },
})
export default class HoursApproval extends Vue {
  private ApprovalTxn;
  private scheduleID = "";

  private loginDetails = {
    title: "Add Associate Login Timings",
    attendenceID: "",
    loginTime: "",
    logoutTime: "",
    loginDate: "",
  };

  private managerApproved = "";
  private verificationType = "manager";
  private searchDate = "";
  private loginHoursDialog = false;
  private submitted = false;
  private approveBoxDialog = false;
  private manualApproveDailog = false;
  private toggleCheckBox = false;
  private activeAssociateID = "";
  private activeAssociateName = "";
  private weekStartingDate = "";
  private weekEndingDate = "";
  private weekNo = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Dashboard", to: "dashboard" },
    { label: "Hours approval" },
  ];
  private toast;
  private associateList: ScheduleList[] = [];
  private approvedList: ScheduleList[] = [];
  private attendanceList: AttendanceList[] = [];
  private approveManually = {
    hours: 0,
    freezed: false,
    shiftDate: "",
    counterId: 0,
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.ApprovalTxn = new ApprovalService();
    this.toast = new Toaster();
  }

  mounted() {
    this.getSchedule();
  }

  setLoginTime(dueTime) {
    this.loginDetails.loginTime = dueTime;
  }

  setLogoutTime(dueTime) {
    this.loginDetails.logoutTime = dueTime;
  }

  parseDate(date) {
    if (date != "") {
      const d2 = moment(String(date)).format("YYYY-MM-DD");
      this.loginDetails.loginDate = d2;
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  getSchedule() {
    // this.associateList = [];
    this.ApprovalTxn.getAssociateShifts(this.searchDate).then((res) => {
      const d = this.camelizeKeys(res);
      const s = d.employeeList.scheduleList;
      if (s != null) {
        this.associateList = s;
      } else {
        this.associateList = [];
      }

      this.scheduleID = d.employeeList.id;
      this.weekStartingDate = d.postStartingDate;
      this.weekEndingDate = d.postEndingDate;
      this.weekNo = d.weekNo;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  get filterAssociates() {
    let copyList: ScheduleList[] = [];

    if (this.associateList != null) {
      copyList = [...this.associateList];
    }

    const uniqueElementsBy = (arr, fn) =>
      arr.reduce((acc, v) => {
        if (!acc.some((x) => fn(v, x))) acc.push(v);
        return acc;
      }, []);

    const list = uniqueElementsBy(
      copyList,
      (a, b) => a.employeeId == b.employeeId
    );

    return list;
  }

  checkApprovedStatus(associateID) {
    let associateStatus = true;

    this.associateList.forEach((e) => {
      if (e.approveStatus == "" && e.employeeId == associateID) {
        associateStatus = false;
      }
    });

    return associateStatus;
  }

  openSchedule(empID, associateName) {
    this.activeAssociateID = empID;
    this.activeAssociateName = associateName;

    this.ApprovalTxn.getAssociateLogin(
      empID,
      this.weekStartingDate,
      this.weekEndingDate
    ).then((res) => {
      const d = this.camelizeKeys(res);
      this.attendanceList = d;
    });
  }

  get filterAssociatePaycodes() {
    const schedule: ScheduleList[] = [];

    this.associateList.forEach((e) => {
      if (e.employeeId == this.activeAssociateID) {
        schedule.push(e);
      }
    });
    return schedule;
  }

  getTimeDiff(end, start) {
    let ms = moment(end, "HH:mm").diff(moment(start, "HH:mm"));
    let d = moment.duration(ms);

    if (d.hours() > 5 && d.hours() <= 10) {
      end = moment(end, "HH:mm").subtract(30, "minutes").format("HH:mm");
    } else if (d.hours() > 10) {
      end = moment(end, "HH:mm").subtract(1, "hour").format("HH:mm");
    }

    ms = moment(end, "HH:mm").diff(moment(start, "HH:mm"));
    d = moment.duration(ms);

    const hours = d.hours();
    const minutes = d.minutes();
    return hours + " Hrs & " + minutes + " min";
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  filterAssociateLoginDetails(empID, date) {
    const logins: AttendanceList[] = [];

    this.attendanceList.forEach((e) => {
      if (e.employeeId == empID && date == e.date && e.timeOut != "00:00:00") {
        logins.push(e);
      }
    });

    return logins;
  }

  filterTotalLogin(empID, date) {
    let totalHrs = 0;

    this.attendanceList.forEach((e) => {
      if (e.employeeId == empID && date == e.date && e.timeOut != "00:00:00") {
        const ms = moment(e.timeOut, "HH:mm").diff(moment(e.timeIn, "HH:mm"));
        const d = moment.duration(ms).asHours();
        totalHrs = totalHrs + d;
      }
    });

    return Math.round(totalHrs * 100) / 100;
  }

  checkAllBoxes() {
    this.toggleCheckBox = !this.toggleCheckBox;

    if (this.toggleCheckBox == true) {
      this.approvedList = this.associateList;
    } else {
      this.approvedList = [];
    }
  }

  acceptApprovedHours() {
    const list: ApprovedList[] = [];
    this.approveBoxDialog = false;
    if (this.approvedList.length > 0) {
      this.approvedList.forEach((e) => {
        if (e.employeeId == this.activeAssociateID) {
          const totalHrs = this.filterTotalLogin(e.employeeId, e.shiftDate);
          list.push({
            counterID: e.counterId,
            approvedHours: totalHrs,
          });
        }
      });

      this.ApprovalTxn.postCheckListApprove(
        this.scheduleID,
        list,
        this.managerApproved
      ).then((res) => {
        this.toast.handleResponse(res);
        this.getSchedule();
        this.approvedList = [];
      });
    } else {
      this.toast.showWarning("Please choose associate shifts to approve");
    }
  }

  openManuyallHours(counterId, associateID, shiftDate) {
    this.manualApproveDailog = true;
    this.approveManually.hours = this.filterTotalLogin(associateID, shiftDate);
    this.approveManually.shiftDate = shiftDate;
    this.approveManually.counterId = counterId;
  }

  saveManuallyHours() {
    this.submitted = true;
    if (this.approveManually.hours > 0) {
      this.ApprovalTxn.updateManualHours(
        this.approveManually,
        this.scheduleID,
        this.managerApproved
      ).then((res) => {
        this.toast.handleResponse(res);
        this.getSchedule();
      });
      this.manualApproveDailog = false;
    }
  }

  openTimingDailog() {
    this.loginHoursDialog = true;
    this.loginDetails.title = "Add Associate Login Timings";
    this.loginDetails.attendenceID = "";
    this.loginDetails.loginTime = "";
    this.loginDetails.logoutTime = "";
    this.loginDetails.loginDate = "";
  }

  addAssociateLogin() {
    if (
      this.loginDetails.loginTime == "" ||
      this.loginDetails.logoutTime == "" ||
      this.loginDetails.loginDate == ""
    ) {
      this.toast.showWarning(
        "Please set date login and logout timings for associate to proceed"
      );
    } else {
      this.ApprovalTxn.addAssociateLogin(
        this.loginDetails.loginTime,
        this.activeAssociateID,
        this.managerApproved,
        this.loginDetails.logoutTime,
        this.loginDetails.loginDate
      ).then((res) => {
        this.toast.handleResponse(res);
        this.openSchedule(this.activeAssociateID, this.activeAssociateName);
      });
      this.loginHoursDialog = false;
    }
  }

  updateAssociateLogin() {
    if (
      this.loginDetails.loginTime == "" ||
      this.loginDetails.logoutTime == "" ||
      this.loginDetails.loginDate == ""
    ) {
      this.toast.showWarning(
        "Please set date login and logout timings for associate to proceed"
      );
    } else {
      this.ApprovalTxn.updateAssociateLogin(
        this.loginDetails.loginTime,
        this.loginDetails.attendenceID,
        this.managerApproved,
        this.loginDetails.logoutTime,
        this.loginDetails.loginDate
      ).then((res) => {
        this.toast.handleResponse(res);
        this.openSchedule(this.activeAssociateID, this.activeAssociateName);
      });
      this.loginHoursDialog = false;
    }
  }

  updateLoginTimes(id, timeOut, timeIn, shiftDate) {
    this.loginHoursDialog = true;
    this.loginDetails.title = "Update Associate Login Timings";
    this.loginDetails.loginTime = timeIn;
    this.loginDetails.attendenceID = id;
    this.loginDetails.logoutTime = timeOut;
    this.loginDetails.loginDate = shiftDate;
  }

  closePINDialog(params) {
    this.managerApproved = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }
}
</script>

<style scoped>
.loggedin-checkbox {
  width: 25px;
  margin-right: 5px;
  margin-top: 5px;
  height: 25px;
}
.highlighted {
  -webkit-animation: NAME-YOUR-ANIMATION 1s infinite; /* Safari 4+ */
  -moz-animation: NAME-YOUR-ANIMATION 1s infinite; /* Fx 5+ */
  -o-animation: NAME-YOUR-ANIMATION 1s infinite; /* Opera 12+ */
  animation: NAME-YOUR-ANIMATION 1s infinite; /* IE 10+, Fx 29+ */
  padding: 0px 15px;
  border-radius: 3px;
}

@-webkit-keyframes NAME-YOUR-ANIMATION {
  0%,
  49% {
    background-color: #004c97;
    border: 3px solid #004c97;
    color: #fff;
  }
  50%,
  100% {
    background-color: #c00;
    border: 3px solid #c00;
    color: #fff;
  }
}

.highlight-associate {
  background-color: #dc4535;
  color: #fff;
  padding: 8px;
  border-radius: 2px;
}

.employee-schedule {
  width: 100%;
  white-space: nowrap;
}
.employee-schedule td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ap-sch-hrs {
  background-color: #eacb00 !important;
  color: #fff;
  font-size: 18px;
}
.ap-chk-hrs {
  background-color: orange !important;
  color: #fff;
  font-size: 18px;
}
.ap-style-hrs {
  background-color: #06af2c !important;
  color: #fff;
  font-size: 18px;
}
.schedule_hrs_chk {
  width: 25px;
  margin-right: 10px;
  height: 25px;
}
.shift-date {
  background-color: #353434;
  color: #fff;
  border-radius: 5px;
  padding: 8px;
}

.schedule-style {
  font-size: 20px;
}

.schedule-style td {
  padding: 10px;
}
.schedule-timings {
  color: #333;
  display: block;
  margin-top: 5px;
}
.schedule-timings:hover {
  background-color: #eee;
  cursor: pointer;
}
</style>
