
import { Options, Vue } from "vue-class-component";
import ItemDetail from "../../service/ItemDetail";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class ItemDetails extends Vue {
  private imagePath = "";
  private lists = [];
  private ItemDetail;
  private productStatus = "";
  private keyword = "";
  private loading = false;
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Item Popups" },
  ];

  private sectionObj = [
    { key: "section_1", value: "Section 1" },
    { key: "section_2", value: "Section 2" },
  ];

  private product = {
    id: 0,
    itemName: "",
    itemDetailName: "",
    price: 0,
    sectionName: "",
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  private allItems;

  private selectedSectionValue = "";
  private selectedSectionObj;

  private selectedItemName = "";
  private selectedItemNameObj;
  private toast;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.ItemDetail = new ItemDetail();
    this.imagePath = this.ItemDetail.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  setDropDownData() {
    this.allItems.filter((elem) => {
      if (elem.id == this.selectedItemName) {
        this.selectedItemNameObj = elem;
      }
    });

    this.sectionObj.filter((elem) => {
      if (elem.value == this.selectedSectionValue) {
        this.selectedSectionObj = elem;
      }
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      itemName: "",
      itemDetailName: "",
      price: 0,
      sectionName: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add Item Details";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.itemDetailName.trim()) {
      if (this.product.id != 0) {
        this.ItemDetail.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.ItemDetail.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        itemName: "",
        itemDetailName: "",
        price: 0,
        sectionName: "",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Item Detail";
    this.productDialog = true;

    this.ItemDetail.getItem(data).then((res) => {
      if (res.length > 0) {
        this.selectedItemName = res[0].item_id;
        this.selectedSectionValue = res[0].section_name;
        this.setDropDownData();

        this.product = {
          id: res[0].item_sp_id,
          itemName: this.selectedItemNameObj,
          itemDetailName: res[0].item_sp_name,
          price: Number(res[0].item_sp_price),
          sectionName: this.selectedSectionObj,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.item_sp_id,
      itemName: "",
      itemDetailName: data.item_sp_name,
      price: 0,
      sectionName: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.ItemDetail.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.ItemDetail.getItems(page).then((data) => {
      this.lists = data.item_details;
      this.totalRecords = data.total_item_count;
      this.limit = data.limit_per_page;
      this.allItems = data.all_item;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.loading = true;
    this.submitted = true;
    if (this.keyword) {
      this.ItemDetail.getSearchedItemDetails(this.keyword).then((data) => {
        this.lists = data.item_details;
        this.allItems = data.all_item;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }
}
