<template>
  <section class="bg-primary">
    <div style="height: 0.1em">
      <ProgressBar
        v-if="showProgress"
        mode="indeterminate"
        style="height: 0.1em"
      />
    </div>
    <div class="single-page">
      <div class="wrapper wrapper2">
        <form method="post" @submit="authenticate" class="card-body">
          <img src="@/assets/images/logo.png" alt="" />
          <div>
            <input
              type="number"
              class="form-control input-lg"
              placeholder="Enter Store ID"
              v-model="StoreID"
              autoFocus
            />
          </div>
          <div>
            <input
              type="password"
              class="form-control input-lg"
              placeholder="Enter Store Password"
              v-model="StorePassword"
            />
          </div>
          <div>
            <input
              type="submit"
              class="btn btn-primary login-btn btn-lg"
              value="Sign in"
            />
          </div>
          <label v-if="errorMessage != ''" class="errorMessage">{{
            errorMessage
          }}</label>
        </form>
        <p>Pos Version 2.0.0</p>
        <small><b>Last update : 2-12-2020</b></small>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import UserAuthentication from "../../service/UserAuthentication";
import router from "../../router";
import Toaster from "../../helpers/Toaster";
import { useStore, ActionTypes } from "../../store";

export default class Login extends Vue {
  private showProgress = false;
  private StoreID = "";
  private StorePassword = "";
  private errorMessage = "";
  private toast;

  created() {
    this.toast = new Toaster();
  }

  authenticate(e) {
    e.preventDefault();
    this.StoreID = this.StoreID.trim();
    this.StorePassword = this.StorePassword.trim();

    if (this.StoreID == "" || this.StorePassword == "") {
      this.errorMessage = "Please enter Store ID and Password";
    } else {
      this.errorMessage = "";
      const auth = new UserAuthentication();
      const store = useStore();

      auth.loginUser(this.StoreID, this.StorePassword).then((res) => {
        if (res.alert == "info") {
          this.StoreID = "";
          this.StorePassword = "";
          this.toast.handleResponse(res);
          store.dispatch(ActionTypes.AUTH_REQUEST, res.auth_token);
          router.replace({ path: "/store/dashboard", params: {} });
        } else {
          store.dispatch(ActionTypes.AUTH_LOGOUT, "");
          this.StoreID = "";
          this.StorePassword = "";
          this.toast.handleResponse(res);
        }
      });
    }
  }
}
</script>

<style scoped>
.containerClass {
  background-color: yellow !important;
}
.bg-primary {
  background-color: #004c97 !important;
  color: #fff !important;
  height: 100vh;
}

.errorMessage {
  color: #c00;
}
/*----Signle-pages----*/

.login-btn {
  border-radius: 5px;
  background-color: #004c97 !important;
}
.single-page {
  width: 30%;
  margin: 0px auto;
  padding-top: 5%;
  display: table;
  position: relative;
  border-radius: 7px;
}

.single-page .wrapper.wrapper2 {
  background: #fff;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  transition: all 0.5s;
  color: #7a7d7d;
  overflow: hidden;
  border-radius: 7px;
  text-align: center;
  padding: 5%;
}

.single-page .wrapper > form {
  width: 100%;
  transition: all 0.5s;
  background: #fff;
  width: 100%;
}

.single-page .wrapper .card-body {
  padding: 30px;
}

.single-page .wrapper > form:focus {
  outline: none;
}

.single-page .wrapper > form > div {
  position: relative;
  margin-bottom: 15px;
}

.single-page .wrapper input {
  height: 40px;
  padding: 5px 15px;
  width: 100%;
  border: solid 1px #e6eaea;
}

.single-page .wrapper input {
  height: 40px;
  padding: 5px 15px;
  width: 100%;
  border: solid 1px #e6eaea;
}

.single-page .wrapper input:focus {
  outline: none;
  border-color: #e6eaea;
}
</style>
