<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :loading="loading"
          :paginator="checkPagination"
          :rows="limit"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="service_name" header="Service"></Column>
          <Column field="sector" header="Department"></Column>
          <Column field="item_id" header="Item ID"></Column>
          <Column field="item_name" header="Item Name"></Column>
          <Column field="item_sp_name" header="Item Detail Name"></Column>
          <Column field="item_sp_price" header="Price"></Column>
          <Column field="section_name" header="Section"></Column>
          <Column field="status" header="Status"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="itemName">Item name:</label>
          <Dropdown
            v-model="product.itemName"
            :options="allItems"
            optionLabel="item_name"
            :filter="true"
            placeholder="Select a Item Name"
          >
            <template #value="slotProps">
              <span>
                Service {{ slotProps.value.service_name }} | Department
                {{ slotProps.value.sector }} | Item Name
                {{ slotProps.value.item_name }}
              </span>
            </template>
            <template #option="slotProps">
              <span>
                Service {{ slotProps.option.service_name }} | Department
                {{ slotProps.option.sector }} | Item Name
                {{ slotProps.option.item_name }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="section">Section:</label>
          <Dropdown
            v-model="product.sectionName"
            :options="sectionObj"
            optionLabel="value"
            placeholder="Select a Section"
          />
        </div>
        <div class="p-field">
          <label for="name">Item Detail Name:</label>
          <InputText
            id="name"
            v-model.trim="product.itemDetailName"
            required="true"
            autofocus
            placeholder="e.g Hem"
            :class="{ 'p-invalid': submitted && !product.itemDetailName }"
          />
          <small class="p-invalid" v-if="submitted && !product.name"
            >Name is required.</small
          >
        </div>

        <div class="p-field">
          <label for="Priority">Item Price:</label>
          <InputNumber
            id="Priority"
            v-model="product.price"
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>

        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>
      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product">
            Change the status of <b>{{ product.itemDetailName }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ItemDetail from "../../service/ItemDetail";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class ItemDetails extends Vue {
  private imagePath = "";
  private lists = [];
  private ItemDetail;
  private productStatus = "";
  private keyword = "";
  private loading = false;
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Item Popups" },
  ];

  private sectionObj = [
    { key: "section_1", value: "Section 1" },
    { key: "section_2", value: "Section 2" },
  ];

  private product = {
    id: 0,
    itemName: "",
    itemDetailName: "",
    price: 0,
    sectionName: "",
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  private allItems;

  private selectedSectionValue = "";
  private selectedSectionObj;

  private selectedItemName = "";
  private selectedItemNameObj;
  private toast;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.ItemDetail = new ItemDetail();
    this.imagePath = this.ItemDetail.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  setDropDownData() {
    this.allItems.filter((elem) => {
      if (elem.id == this.selectedItemName) {
        this.selectedItemNameObj = elem;
      }
    });

    this.sectionObj.filter((elem) => {
      if (elem.value == this.selectedSectionValue) {
        this.selectedSectionObj = elem;
      }
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      itemName: "",
      itemDetailName: "",
      price: 0,
      sectionName: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add Item Details";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.itemDetailName.trim()) {
      if (this.product.id != 0) {
        this.ItemDetail.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.ItemDetail.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        itemName: "",
        itemDetailName: "",
        price: 0,
        sectionName: "",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Item Detail";
    this.productDialog = true;

    this.ItemDetail.getItem(data).then((res) => {
      if (res.length > 0) {
        this.selectedItemName = res[0].item_id;
        this.selectedSectionValue = res[0].section_name;
        this.setDropDownData();

        this.product = {
          id: res[0].item_sp_id,
          itemName: this.selectedItemNameObj,
          itemDetailName: res[0].item_sp_name,
          price: Number(res[0].item_sp_price),
          sectionName: this.selectedSectionObj,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.item_sp_id,
      itemName: "",
      itemDetailName: data.item_sp_name,
      price: 0,
      sectionName: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.ItemDetail.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.ItemDetail.getItems(page).then((data) => {
      this.lists = data.item_details;
      this.totalRecords = data.total_item_count;
      this.limit = data.limit_per_page;
      this.allItems = data.all_item;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.loading = true;
    this.submitted = true;
    if (this.keyword) {
      this.ItemDetail.getSearchedItemDetails(this.keyword).then((data) => {
        this.lists = data.item_details;
        this.allItems = data.all_item;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>